
import mixins from "vue-typed-mixins";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import core from "@/core";
import EstimateService from "@/services/work/estimate.service";
import ScheduleService from "@/services/work/schedule.service";
import ColorItems from "@/data/color-items.js";
import CategoryService from "@/services/work/category.service";
import CompanyService from "@/services/company/company.service";
import TeamService from "@/services/team/team.service";
import ScheduleUtils, { ScheduleViewType } from "@/models/schedule";
import PlaceService from "@/services/work/place.service";
import store from "@/store";
import MixinsStorage from "@/mixins/single/storage";
import MixinsComponent from "@/mixins/component";
import MixinsPageForm from "@/mixins/page-form";
import UpdateEvent from "@/models";
import { EntityType, UpdateEventType } from "@/types";
import { SimpleUserModel } from "@/models/user/user.model";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    title: "제목",
    startAt: "시작일정",
    endAt: "종료일정",
    date: "일정",
    categoryId: "품목",
    user: "담당자",
    team: "팀",
  },
});

export default mixins(MixinsPageForm, MixinsComponent, MixinsStorage).extend({
  name: "ScheduleAddEditComponent",

  data: () => ({
    storageItems: {
      type: "schedule-add-edit",
    },
    app: store.state.app,
    title: "",
    type: "",
    form: {
      title: "",
      memo: "",
      workType: "",
      allDay: true,
      startAt: "",
      endAt: "",
      color: "teal",
      categoryId: null as any,
      // userId: null as number | null,
      // subUserIdList: null as number[] | null,
      teamId: null as any,
      estimateId: null as any,
      teamPrice: "",
      topFixed: false,
    },
    view: {
      // user: "",
      // subUser: "",
      team: "",
      totalPrice: null,
      teamPrice: "",
    },
    validateRules: {
      team: "",
    },
    placeList: [] as any,

    estimateId: null as number | null,
    estimate: null as any,
    scheduleList: null as any,
    selectedEstimateDetailList: [] as any,
    formProcessing: false,
    categoryPromiseResolve: null as any,
    categoryList: [] as any,
    emptyCategory: { name: "없음", id: null },
    colorItems: ColorItems,

    scheduleId: null as number | null,
    schedule: null as any,
    viewType: ScheduleViewType.ALL,
    visible: {
      category: false, // 품목 표시 여부
      team: false, // 팀 표시 여부
      delete: false, // 삭제 버튼 표시 여부
      user: false, // 담당자 표시 여부
      workScope: false, // 작업범위 표시 여부
      teamPrice: false, // 팀 금액 표시 여부
      price: false,
    },
    // 견적 상세 목록
    estimateDetailList: [] as any,

    // 담당자 관련
    user: {
      map: new Map(),
      main: {
        filterList: [] as any,
        text: null as string | null,
        selected: null as any,
      },
      sub: {
        filterList: [] as any,
        text: null as string | null,
        selected: null as any,
      },
    },

    // 팀 목록
    teamList: [] as any,
    teamFilterList: [] as any,
    modal: {
      timepicker: {
        start: "",
        visible: false,
        params: {
          time: "",
          type: "",
        },
        // 업데이트 이벤트 처리용 변수
        updateEvent: null as any as UpdateEvent | null,
      },
      user: {
        visible: false,
      },
      subUser: {
        visible: false,
      },
      team: {
        visible: false,
      },
    },
  }),

  async mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },

  watch: {
    "user.main.text"(val) {
      if (this.isBlank(val)) {
        this.user.main.selected = null;
      }
    },
    "user.main.selected"(val) {
      //console.log("user main selected : ", val);
      if (val != null) {
        this.user.main.text = val.name;
        this.changedUserMainFilterList(this.user.main.filterList);
      } else {
        this.user.main.text = null;
        this.user.sub.selected = null;
      }
    },
    "user.sub.text"(val) {
      if (this.isBlank(val)) {
        this.user.sub.selected = null;
      }
    },
    "user.sub.selected"(val) {
      //console.log("user sub selected : ", val);
      if (val != null) {
        const nameList = [] as any;
        val.forEach((item) => {
          nameList.push(item.name);
        });
        this.user.sub.text = nameList.join(",");
      } else {
        this.user.sub.text = null;
      }
    },
    "view.team"(val) {
      if (this.isBlank(val)) {
        this.form.teamId = null;
      }
    },
    selectedEstimateDetailList(val) {
      if (val != null) {
        let totalPrice = 0;
        val.forEach((item) => {
          totalPrice += item.price - item.discount;
        });
        if (totalPrice > 0) {
          const strTotalPrice = String(totalPrice);
          this.view.totalPrice = this.moneyFormat(strTotalPrice) as any;
          this.view.teamPrice = (Number(core.utils.format.onlyNumber(strTotalPrice)) /
            10000) as any;
          console.log("change view teamPrice");
        } else {
          this.view.totalPrice = null;
          this.view.teamPrice = "";
        }
      }
    },
    "form.title"(title) {
      this.changeValue();
    },
    "form.memo"() {
      this.changeValue();
    },
    "form.workType"() {
      this.changeValue();
    },
    "form.startAt"(val, prev) {
      this.changeValue();
    },
    "form.endAt"(val, prev) {
      this.changeValue();
    },
    "form.teamId"(val, prev) {
      this.changeValue();
      this.changedTeamId();
    },
    "form.categoryId"(val, prev) {
      this.changeValue();

      this.changeCategoryId(val);

      if (this.categoryPromiseResolve != null) {
        this.categoryPromiseResolve();
        this.categoryPromiseResolve = null;
      }
    },
    "modal.timepicker.updateEvent"(val) {
      const event = this.getComponentUpdateEvent(this.modal.timepicker);
      if (event != null && event.result == UpdateEventType.CONFIRM) {
        const item = event.item;
        if (item.type === "startAt") {
          const prev = this.form.startAt;
          const startAt =
            core.date.instance(this.form.startAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(startAt, this.form.endAt, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else if (item.type === "endAt") {
          const prev = this.form.endAt;
          const endAt =
            core.date.instance(this.form.endAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else {
          console.log("Unknown item, ", item);
        }
      }
    },
    "user.main.filterList"(val) {
      this.changedUserMainFilterList(val);
    },

    "properties.updateEvent"() {
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          const item = event.item;
          if (item.returnType === "startAt") {
            const prev = this.form.startAt;
            const startAt = item.date + core.date.instance(prev).format("THH:mm:ss");
            const calendar = core.date.calendar(startAt, this.form.endAt, prev);
            this.form.startAt = calendar.startAt;
            this.form.endAt = calendar.endAt;
          } else if (item.returnType === "endAt") {
            const prev = this.form.endAt;
            const endAt = item.date + core.date.instance(prev).format("THH:mm:ss");
            const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
            this.form.startAt = calendar.startAt;
            this.form.endAt = calendar.endAt;
          } else {
            console.log("unknown returnType : ", item.returnType);
          }
        }
      }
    },
  },

  // activated() {
  //   const event = this.getPageUpdateEvent();
  //   if (event != null) {
  //     if (event.result === UpdateEventType.UPDATE) {
  //       const item = event.item;
  //       if (item.returnType === "startAt") {
  //         const prev = this.form.startAt;
  //         const startAt = item.date + core.date.instance(prev).format("THH:mm:ss");
  //         const calendar = core.date.calendar(startAt, this.form.endAt, prev);
  //         this.form.startAt = calendar.startAt;
  //         this.form.endAt = calendar.endAt;
  //       } else if (item.returnType === "endAt") {
  //         const prev = this.form.endAt;
  //         const endAt = item.date + core.date.instance(prev).format("THH:mm:ss");
  //         const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
  //         this.form.startAt = calendar.startAt;
  //         this.form.endAt = calendar.endAt;
  //       } else {
  //         console.log("unknown returnType : ", item.returnType);
  //       }
  //     }
  //   }
  // },
  methods: {
    async changedTeamId() {
      if (this.isNotBlank(this.form.teamId)) {
        const teamId = Number(this.form.teamId);
        console.log("changed teamId : ", teamId);
        let team = null as any;
        this.teamList.some((_team) => {
          if (_team.id === teamId) {
            team = _team;
            return true;
          }
        });
        if (team != null) {
          this.visible.teamPrice =
            team.titleViewType === "PRICE_ALL" || team.titleViewType === "PRICE_LEADER";
          if (this.schedule != null) {
            const price = this.schedule.totalPrice + this.schedule.adjustPrice;
            //console.log("price : ", price);
            this.view.teamPrice = this.onlyMoneyFormat(price);
            //console.log("this.view.teamPrice : ", this.view.teamPrice);
          }
        } else {
          this.visible.teamPrice = false;
        }
        const myUser = this.myUser;
        if (this.isCompanyManagerRoleHigher) {
          // 사용자 목록 조회
          this.user.main.filterList = await TeamService.getUserList(teamId);
        } else {
          if (myUser.company.teamList != null) {
            for (const team of myUser.company.teamList) {
              if (teamId === team.id) {
                if (team.role === "LEADER") {
                  // 사용자 목록 조회
                  this.user.main.filterList = await TeamService.getUserList(team.id);
                } else {
                  this.user.main.filterList = this.mapToList(this.user.map);
                }
              }
            }
          }
        }
      } else {
        this.visible.teamPrice = false;
        this.user.main.filterList = this.mapToList(this.user.map);
      }
    },
    mapToList(map) {
      const list = [] as any;
      map.forEach((item) => {
        list.push(item);
      });
      return list;
    },
    changedUserMainFilterList(list) {
      // console.log("changedUserMainFilterList : ", list);
      const user = this.user;
      if (list != null) {
        if (user.main.selected) {
          const filterList = [] as any;
          list.forEach((item) => {
            if (item.id != user.main.selected.id) {
              filterList.push(item);
            }
          });
          user.sub.filterList = filterList;
          if (user.sub.selected) {
            const selected = [] as any;
            user.sub.selected.forEach((_user) => {
              if (_user.id != user.main.selected.id) {
                selected.push(_user);
              }
            });
            this.user.sub.selected = selected;
          }
          // console.log("changed sub filterList : ", user.sub.filterList);
        } else {
          user.sub.filterList = list;
        }
      } else {
        user.sub.filterList = list;
      }
    },
    onlyMoneyFormat(str: string) {
      // const money = Number(this.onlyNumber(str)) / 10000;
      // return String(money).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "만원";
      const money = Number(core.utils.format.onlyNumber(str));
      const temp = money / 10000;
      return String(temp);
    },
    async init() {
      await this.storageWait();
      const items = this.getStorageItems();
      if (items.placeTeams == null) {
        items.placeTeams = {};
        this.saveStorageItems();
      }
      //console.log("items : ", items);

      const params = this.$route.params as any;
      const type = (this.type = params.type as string);
      const query = this.$route.query as any;
      let viewType = ScheduleUtils.getViewType(query.viewType);
      if (viewType == null) {
        if (this.isBlank(query.estimateId)) {
          viewType = ScheduleViewType.ALL;
          // console.log("not found viewType");
          // await this.notFound();
          // return;
        } else {
          viewType = ScheduleViewType.ESTIMATE;
        }
      }
      this.viewType = viewType;

      if (query.startDate != null) {
        this.form.startAt = core.date
          .instance(query.startDate as string)
          .format("YYYY-MM-DDT00:00:00");
      }
      if (query.endDate != null) {
        this.form.endAt = core.date.instance(query.endDate as string).format("YYYY-MM-DDT00:00:00");
      }

      const form = this.form;
      const myUser = this.myUser;
      const visible = this.visible;

      if (this.isCompanyManagerRoleHigher) {
        visible.price = true;
      }

      if (type === "add") {
        this.form.workType = "D";
      } else if (type === "as") {
        this.form.workType = "A";
      }

      if (type === "add" || type === "as") {
        // 견적 ID 존재 할 경우
        if (this.isNotBlank(query.estimateId)) {
          if (query.estimateId !== "null") {
            form.estimateId = Number(query.estimateId) as any;
            try {
              core.loader.show();
              await this.loadEstimate(Number(form.estimateId));
            } catch (e) {
              return;
            }
          } else {
            form.estimateId = "null";
          }
        }

        if (this.isNotBlank(query.teamId)) {
          if (query.teamId !== "null") {
            try {
              const teamId = Number(query.teamId);
              const team = (await TeamService.get(teamId as any)) as any;
              this.teamList.push(team);
              form.teamId = teamId as any;
              form.categoryId = team.category.id as any;
            } catch (e) {
              await this.notFound();
              return;
            }
          } else {
            form.teamId = "null";
          }
        } else if (this.isNotBlank(query.categoryId)) {
          if (query.categoryId !== "null") {
            form.categoryId = Number(query.categoryId) as any;
            visible.team = true;
          } else {
            form.categoryId = "null";
          }
        } else {
          visible.category = true;
          visible.team = true;
        }
      } else {
        if (viewType === ScheduleViewType.DEFAULT) {
          form.categoryId = null;
          form.teamId = null;
        } else {
          visible.category = true;
          visible.team = true;
        }
      }

      if (visible.team) {
        ((await this.$store.getters["app/getTeamList"]()) as any).forEach((team) => {
          team.text = `[${team.category.name}] ${team.name}`;
          this.teamList.push(team);
        });
        if (form.categoryId != null) {
          this.changeCategoryId(form.categoryId);
        }
      }

      if (visible.category) {
        // 품목
        const categoryList = [] as any;
        let categoryLength = 1;
        if (type !== "as") {
          categoryList.push(this.emptyCategory);
          categoryLength = 2;
        }

        let noneCategory = false;
        if (this.estimate != null) {
          noneCategory = this.isBlank(this.estimate.categoryText);
        }
        ((await this.$store.getters["app/getCategoryList"]()) as any).forEach((category) => {
          if (this.estimate != null) {
            if (this.estimate.categoryItems[category.id] || (type === "as" && noneCategory)) {
              categoryList.push(category);
            }
          } else {
            categoryList.push(category);
          }
        });
        this.categoryList = categoryList;
        if (categoryList.length === categoryLength) {
          form.categoryId = categoryList[categoryLength - 1].id;
        } else {
          form.categoryId = null;
        }
      }

      if (this.isCompanyManagerRoleHigher) {
        visible.user = true;
        const list = (await CompanyService.getUserList({
          lastApprovalType: "ALLOW",
        })) as any;
        //console.log("list : ", list);
        list.forEach((user) => {
          this.user.map.set(user.id, user);
        });
        this.user.main.filterList = this.mapToList(this.user.map);
      } else {
        visible.user = true;
        if (myUser.company.teamList != null) {
          for (const team of myUser.company.teamList) {
            //console.log("team : ", team);
            if (team.role === "LEADER") {
              // 사용자 목록 조회
              team.userList = (await TeamService.getUserList(team.id)) as SimpleUserModel[];
              //console.log("team.userList : ", team.userList, ", form.teamId : ", form.teamId);
              if (form.teamId == team.id) {
                const list = team.userList;
                list.forEach((user) => {
                  this.user.map.set(user.id, user);
                });
              }
            }
          }
        }
      }

      if (type === "add") {
        this.title = "일정 추가";
        const estimate = this.estimate;
        if (estimate != null) {
          this.form.workType = "D";
          this.form.title = `${estimate.place.name} ${estimate.dong}-${estimate.ho}`;
          this.form.memo = "";
          this.form.allDay = true;
          this.form.startAt = core.date.instance().format("YYYY-MM-DDT00:00:00");
          this.form.endAt = core.date.instance().format("YYYY-MM-DDT00:00:00");
          this.form.color = estimate.place.color;
        } else {
          this.placeList = await this.$store.getters["app/getPlaceList"]();
        }

        if (this.isCompanyUserRole) {
          this.user.main.selected = myUser;
          // form.userId = myUser.id;
          // this.view.user = myUser.name;
        }

        // if (form.workType !== "A" && form.categoryId != null && this.estimate != null) {
        //   this.visible.workScope = true;
        // }
      } else if (type === "edit") {
        this.title = "일정 수정";
        this.scheduleId = Number(query.id);
        if (this.scheduleId <= 0) {
          await this.notFound();
          return;
        }

        try {
          core.loader.show();
          await this.loadSchedule(Number(this.scheduleId));
        } catch (e) {
          this.goBack();
          return;
        }
        const schedule = this.schedule as any;
        // console.log("schedule : ", schedule);
        const form: any = this.form;
        for (const key of Object.keys(form)) {
          const value = schedule?.[key];
          if (value != null) {
            form[key] = value;
            //console.log(`key : ${key}, value : ${value}`);
          }
        }

        const wait = () => {
          return new Promise((resolve) => {
            this.categoryPromiseResolve = resolve;
          });
        };

        this.form.categoryId = schedule?.category ? schedule?.category.id : null;
        if (this.form.categoryId != null) {
          await wait();
        }

        this.form.teamId = schedule?.teamId;
        if (this.form.teamId != null) {
          this.teamList.some((team) => {
            if (team.id == Number(this.form.teamId)) {
              this.view.team = `[${team.category.name}] ${team.name}`;
              return true;
            }
          });
        }

        if (schedule.userList != null) {
          schedule.userList.forEach((scheduleUser) => {
            if (scheduleUser.type === "MAIN") {
              let user = this.user.map.get(scheduleUser.id);
              if (user == null) {
                //console.log("not found user : ", scheduleUser, this.user.map);
                user = scheduleUser;
              }
              this.user.main.selected = user;
            } else {
              if (this.user.sub.selected == null) {
                this.user.sub.selected = [];
              }
              let user = this.user.map.get(scheduleUser.id);
              if (user == null) {
                //console.log("not found user : ", scheduleUser, this.user.map);
                user = scheduleUser;
              }
              this.user.sub.selected.push(user);
            }
          });
        }

        if (this.isCompanyUserRole) {
          if (this.user.map.size === 0) {
            this.user.map.set(myUser.id, myUser);
          }
        }
        // console.log("this.userList : ", this.userList);
        // console.log("userFilterList : ", this.userFilterList);
      } else if (type === "as") {
        this.title = "A/S 추가";

        const estimate = this.estimate;

        this.form.workType = "A";
        this.form.title = `A/S ${estimate.place.name} ${estimate.dong}-${estimate.ho}`;
        this.form.memo = "";
        this.form.allDay = true;
        this.form.startAt = core.date.instance().format("YYYY-MM-DDT00:00:00");
        this.form.endAt = core.date.instance().format("YYYY-MM-DDT00:00:00");
        this.form.color = estimate.place.color;

        if (this.isCompanyUserRole) {
          this.user.main.selected = myUser;
        }
      }
      await this.changedTeamId();

      core.loader.hide();
      const changeValue = this.changeValue;
      setTimeout(() => {
        changeValue(false);
      }, 500);
    },
    async checkTitle() {
      let title = this.form.title;
      if (this.type === "add" && this.isNotBlank(title)) {
        // 신규 일정 생성시 견적서 자동 생성 여부 판단
        console.log("title : ", title);

        let isAs = false;
        // AS 자르기
        const regexp = new RegExp(/[Aaㅁ]([/.]?)[Ssㄴ]/, "g");
        const match = title.match(regexp);
        if (match) {
          console.log("A/S 등록");
          isAs = true;
          match.forEach((str) => {
            console.log("변경!! : ", str);
            if (str !== "A/S") {
              title = title.replace(str, `A/S`);
            }
          });
          console.log("changed title : ", title);
          this.form.title = title;
        }

        let placeName = title.replace("A/S", "").trim();
        const dongHoResult = core.utils.format.textToDongHo(placeName) as any;
        // console.log("dongHoResult : ", dongHoResult);
        if (dongHoResult != null) {
          console.log(`replace content ${placeName} -> ${dongHoResult.replaceContent}`);
          if (dongHoResult.list.length > 0) {
            const pos = placeName.indexOf(dongHoResult.list[0].dong);
            placeName = placeName.substr(0, pos).trim();
            const dongHo = dongHoResult.list[0];

            // console.log("장소명 검색 : ", placeName);
            // console.log("dongHo : ", dongHo);

            const placeList = [] as any;
            if (this.placeList != null) {
              this.placeList.some((place) => {
                let pos = place.name.indexOf(placeName);
                if (pos == -1) {
                  pos = placeName.indexOf(place.name);
                }

                if (pos > -1) {
                  placeList.push(place);
                }
              });
            }

            if (placeList.length === 1) {
              const place = placeList[0];
              // 견적 확인
              const params = {
                entityType: EntityType.SCHEDULE,
                placeId: place.id,
                dong: dongHo.dong,
                ho: dongHo.ho,
              } as any;
              const apiResult = (await EstimateService.existsEstimate(params)) as any;
              if (apiResult.exists) {
                this.$route.query.estimateId = apiResult.estimateId;
                const type = this.$route.params.type;
                this.$route.params.type = isAs ? "as" : type;
                await this.init();
              }
            }
            console.log(placeList);
          }
        }
      }
    },
    changeCategoryId(categoryId) {
      //console.log("categoryId : ", categoryId);
      if (categoryId != null) {
        if (this.visible.team && this.teamList != null) {
          this.teamFilterList = [] as any;
          this.teamList.forEach((team) => {
            if (team.category.id == Number(categoryId) && team.enabled) {
              this.teamFilterList.push(team);
            }
          });
          this.form.teamId = null;
          if (this.type === "add" || this.type === "as") {
            if (this.teamFilterList.length === 1) {
              this.form.teamId = this.teamFilterList[0].id;
              this.view.team = this.teamFilterList[0].text;
            } else {
              let teamId = null as any;
              if (this.estimate != null && this.estimate.categoryList != null) {
                this.estimate.categoryList.some((estimateCategory) => {
                  if (estimateCategory.category.id == categoryId) {
                    if (estimateCategory.team != null) {
                      teamId = estimateCategory.team.id;
                    } else {
                      teamId = null;
                    }
                    return true;
                  }
                });
              }

              //console.log("teamId : ", teamId);
              if (teamId == null) {
                const items = this.getStorageItems();
                if (
                  this.estimate != null &&
                  items.placeTeams != null &&
                  items.placeTeams[this.estimate.place.id]
                ) {
                  this.teamFilterList.some((team) => {
                    if (team.id === items.placeTeams[this.estimate.place.id]) {
                      this.form.teamId = team.id;
                      this.view.team = team.text;
                      return true;
                    }
                  });
                }
              } else {
                this.teamFilterList.some((team) => {
                  if (team.id === teamId) {
                    this.form.teamId = team.id;
                    this.view.team = team.text;
                    return true;
                  }
                });
              }
            }
          }
          if (this.teamFilterList.length > 0) {
            this.validateRules.team = "required";
          } else {
            this.validateRules.team = "";
          }
          console.log("validateRules: ", this.validateRules);
        }

        console.log("workType : ", this.form.workType);
        if (this.estimate != null && this.form.workType === "D") {
          this.visible.workScope = true;

          const items = this.estimate.categoryItems[categoryId];
          this.selectedEstimateDetailList = [];
          if (items != null) {
            // console.log("items : {}", items);
            this.estimateDetailList = items;

            if (this.type === "add") {
              this.estimateDetailList.forEach((item) => {
                this.selectedEstimateDetailList.push(item);
              });
            } else if (this.type === "edit") {
              // 작업범위 선택
              this.schedule.detailList.forEach((scheduleDetail: any) => {
                //console.log("scheduleDetail : ", scheduleDetail);
                this.estimateDetailList.some((estimateDetail: any) => {
                  if (estimateDetail.id == scheduleDetail.estimateDetailId) {
                    this.selectedEstimateDetailList.push(estimateDetail);
                    return true;
                  }
                });
              });
            }
          } else {
            console.log("품목 아이템 없음! : ", this.estimate.categoryItems);
            this.estimateDetailList = [];
          }
        }
      } else {
        if (this.visible.team && this.teamList != null) {
          this.teamFilterList = [] as any;
          if (this.type === "add") {
            this.form.teamId = null;
            this.view.team = "없음";
          }
        }
      }
    },
    getDate(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("YYYY년MM월DD일(ddd)");
    },
    getTime(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("a hh:mm");
    },
    clickAllDay() {
      this.form.allDay = !this.form.allDay;
      this.changeValue();

      this.changedAllDay();
    },
    changedAllDay() {
      const allDay = this.form.allDay;
      if (!allDay) {
        const sec = core.date
          .instance(this.form.endAt)
          .diff(core.date.instance(this.form.startAt), "seconds");
        if (sec == 0) {
          const startHour = core.date.instance().add(1, "hours").format("HH");
          const endHour = core.date.instance().add(2, "hours").format("HH");
          this.form.startAt = core.date
            .instance(this.form.startAt)
            .format("YYYY-MM-DDT" + startHour + ":00:00");
          this.form.endAt = core.date
            .instance(this.form.endAt)
            .format("YYYY-MM-DDT" + endHour + ":00:00");
        }
        console.log(`change allday, startAt ${this.form.startAt}, endAt ${this.form.endAt}`);
      }
    },
    showScheduleSelectPage(returnType: string, date: string) {
      const moment = core.date.instance(date);
      const query = {
        month: moment.format("YYYY-MM"),
        returnType: returnType,
        viewType: this.viewType,
      } as any;

      if (this.isNotBlank(this.$route.query.teamId)) {
        query.teamId = this.$route.query.teamId;
      } else {
        if (this.form.estimateId === "null") {
          query.estimateId = this.form.estimateId;
        }

        if (this.form.categoryId != null) {
          query.categoryId = this.form.categoryId;
        }

        if (this.form.teamId != null) {
          query.teamId = this.form.teamId;
        }
      }

      this.$router.push({
        path: `/schedule/select`,
        query: query,
      });
    },
    showScheduleTimeModal(type: string, at: string) {
      const moment = core.date.instance(at);
      const modal = this.modal.timepicker;
      modal.params.time = moment.format("HH:mm");
      modal.params.type = type;
      modal.start = "";
      if (type === "startAt" && this.schedule != null && this.schedule.estimateId != null) {
        // console.log("change start time");
        modal.start = "06:00";
      } else if (type === "endAt") {
        const startAtMoment = core.date.instance(this.form.startAt);
        const endAtMoment = core.date.instance(this.form.endAt);
        const strStartAt = startAtMoment.format("YYYYMMDD");
        const strEndAt = endAtMoment.format("YYYYMMDD");
        //console.log(`strStartAt : ${strStartAt}, strEndAt : ${strEndAt}`);
        if (strStartAt === strEndAt) {
          modal.start = core.date.instance(this.form.startAt).format("HH:mm");
        }
      }
      modal.visible = true;
    },
    changeValue(changed?: boolean) {
      if (changed != null) {
        this.changeBackButton(changed);
      } else {
        this.changeBackButton(true);
      }
    },
    async deleteItem() {
      const schedule = this.schedule;
      if (schedule != null) {
        const result = await core.alert.show({
          title: "확인",
          body: "현재 일정을 삭제하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });

        if (result === "confirm") {
          try {
            await ScheduleService.delete(schedule.id);
            this.goBack(UpdateEventType.DELETE, schedule.id);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    async loadSchedule(scheduleId: number) {
      const schedule = (await ScheduleService.get(scheduleId)) as any;
      if (schedule.estimateId != null) {
        this.visible.delete = true;
      } else {
        this.visible.delete = true;
      }
      this.schedule = schedule;
      if (schedule.estimateId != null) {
        this.estimateId = schedule.estimateId;
        await this.loadEstimate(Number(this.estimateId));
      }

      return schedule;
    },
    async loadEstimate(estimateId: number) {
      const schedule = this.schedule;
      const estimate = (await EstimateService.get(estimateId)) as any;
      // console.log("estimate : ", estimate);

      if (this.type !== "as") {
        const scheduleList = (await ScheduleService.getBySearchColumns({
          estimateId: estimateId,
        })) as any;
        //console.log("scheduleList : ", scheduleList);

        if (estimate.detailList != null) {
          // 견적 상세 목록에, 일정 상세 ID 추가 매핑
          estimate.scheduleEmptyDetailList = [];
          const categoryItems = {} as any;
          estimate.detailList.forEach((detail: any) => {
            try {
              let scheduleId = null;
              let scheduleDetailId = null;
              scheduleList.some((schedule: any) => {
                if (schedule.detailList == null) schedule.detailList = [];
                schedule.detailList.some((scheduleDetail: any) => {
                  if (scheduleDetail.estimateDetailId == detail.id) {
                    scheduleId = schedule.id;
                    scheduleDetailId = scheduleDetail.id;
                    return true;
                  }
                });
                return scheduleDetailId != null;
              });

              let addItem = false;
              if (scheduleDetailId) {
                detail.scheduleDetailId = scheduleDetailId;
                if (schedule != null && schedule.id == scheduleId) {
                  addItem = true;
                }
              } else {
                addItem = true;
              }
              if (addItem) {
                let items = categoryItems[detail.category.id] as any;
                if (items == null) {
                  items = [];
                  categoryItems[detail.category.id] = items;
                }
                items.push(detail);
              }
            } catch (e) {
              console.log(e);
            }
          });
          estimate.categoryItems = categoryItems;
          //console.log(estimate.categoryItems);
          if (this.type === "add") {
            const keys = Object.keys(categoryItems);
            if (keys.length === 0) {
              this.changeValue(false);
              await core.alert.show({
                title: "알림",
                body: `${estimate.place.name} ${estimate.dong}동 ${estimate.ho}호는 작업일정이 확정되어 추가할 수 없습니다`,
              });
              await this.goBack();
              throw new Error("error");
            } else {
              // 일정 추가 가능한 품목만 표시
              const list = [] as any;
              this.categoryList.forEach((item) => {
                if (item.id == null) {
                  list.push(item);
                } else if (estimate.categoryItems[item.id]) {
                  list.push(item);
                }
              });
              this.categoryList = list;

              if (list.length === 2) {
                // console.log("categoryLength : ", list.length);
                this.form.categoryId = list[1].id;
              }
            }
          }
        } else {
          console.log("견적 상세 정보 없음");
        }
        this.scheduleList = scheduleList;
      } else {
        if (estimate.detailList != null) {
          // 견적 상세 목록에, 일정 상세 ID 추가 매핑
          const categoryItems = {} as any;
          estimate.detailList.forEach((detail: any) => {
            let items = categoryItems[detail.category.id] as any;
            if (items == null) {
              items = [];
              categoryItems[detail.category.id] = items;
            }
            items.push(detail);
          });
          estimate.categoryItems = categoryItems;
        } else {
          console.log("견적 상세 정보 없음");
        }
      }
      this.estimate = estimate;
      return estimate;
    },
    async submit() {
      console.log("submit");
      if (await this.validate()) {
        core.loader.show();
        try {
          const params = core.utils.deepCopy(this.form);
          if (this.user.main.selected) {
            params.userList = [this.user.main.selected.id];

            if (this.user.sub.selected) {
              this.user.sub.selected.forEach((user) => {
                params.userList.push(user.id);
              });
            }
          }

          let schedule = null as any;
          if (this.type === "add") {
            if (this.estimate != null) {
              params.estimateId = this.estimate.id;
              params.estimateDetailIdList = [] as any;
              if (this.selectedEstimateDetailList.length === 0) {
                await core.alert.show({
                  title: "알림",
                  body: "작업 범위를 1개 이상 선택해야합니다",
                });
                return;
              }
              let totalPrice = 0;
              this.selectedEstimateDetailList.forEach((detail: any) => {
                params.estimateDetailIdList.push(detail.id);
                totalPrice += detail.price - detail.discount;
              });

              if (this.visible.teamPrice) {
                params.adjustPrice = Number(this.view.teamPrice) * 10000 - totalPrice;
              } else {
                params.adjustPrice = 0;
              }

              //console.log("params : ", params);
            }
            schedule = await ScheduleService.create(params);
          } else if (this.type === "as") {
            schedule = await ScheduleService.create(params);
          } else if (this.type === "edit") {
            const orgSchedule = this.schedule;
            if (orgSchedule.detailList != null && orgSchedule.estimateId != null) {
              params.estimateDetailIdList = [];
              if (this.selectedEstimateDetailList.length === 0 && this.form.workType === "D") {
                await core.alert.show({
                  title: "알림",
                  body: "작업 범위를 1개 이상 선택해야합니다",
                });
                return;
              }
              let totalPrice = 0;
              this.selectedEstimateDetailList.forEach((detail: any) => {
                params.estimateDetailIdList.push(detail.id);
                totalPrice += detail.price - detail.discount;
              });
              if (this.visible.teamPrice) {
                params.adjustPrice = Number(this.view.teamPrice) * 10000 - totalPrice;
              } else {
                params.adjustPrice = 0;
              }
              console.log("params : ", params);
            }
            schedule = await ScheduleService.update(orgSchedule.id, params);
          } else {
            console.log("unknown type : ", this.type);
          }

          if (schedule != null) {
            console.log(this.type + ", schedule : ", schedule);
            if (this.estimate != null && schedule.teamId) {
              const place = this.estimate.place;
              const items = this.getStorageItems();
              items.placeTeams[place.id] = schedule.teamId;
              this.saveStorageItems();
            }
          }

          this.goBack(UpdateEventType.RELOAD, schedule);
        } catch (e) {
          this.errorSubmit(e);
        }
        core.loader.hide();
      }
    },
  },
});
