
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import Constant from "@/store/constant";

export default mixins(MixinsPageForm).extend({
  name: "AppInstall",
  components: {},
  data: () => ({
    ready: false,
    processing: false,
    visible: {
      ios: false,
    },
  }),

  mounted() {
    this.$nextTick(async () => {
      try {
        if (!core.mobile.isApp()) {
          const platform = core.utils.platform();
          if (platform === "ios") {
            this.visible.ios = true;
          }
        }

        this.ready = true;
      } catch (e) {
        console.log(e);
      }
    });
  },
  methods: {
    moveIosStore() {
      location.href = Constant.iosStoreUrl;
    },
    async submit() {
      console.log("submit");

      // 앱에서 열도록 처리
      // if (!core.mobile.isApp()) {
      //   const platform = core.utils.platform();
      //   if (platform === "android") {
      //     setTimeout(function () {
      //       const path = "/";
      //       location.href =
      //         "intent://action?path=" +
      //         path +
      //         "#Intent;scheme=smartline;package=kr.co.scc20.smartline;end";
      //     }, 200);
      //     return;
      //   } else if (platform === "ios") {
      //     setTimeout(function () {
      //       // https://apps.apple.com/kr/app/id6446343053
      //       try {
      //         // location.href = "https://manager.highline.co.kr";
      //         // location.href = "smartline://action?path=/";
      //         location.href = Constant.iosStoreUrl;
      //       } catch (e) {
      //         console.log("error : ", e);
      //       }
      //     }, 200);
      //     return;
      //   }
      // }

      // 견적서 받기 처리
      this.processing = true;
      try {
        if (core.mobile.isApp()) {
          this.$router.back();
        } else {
          console.log("zz");
        }
      } finally {
        this.processing = false;
      }
    },
  },
});
