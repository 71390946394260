
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UpdateEvent from "@/models";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import ColorItems from "@/data/color-items.js";
import PlaceService from "@/services/work/place.service";
import { UserModel } from "@/models/user/user.model";
import { UpdateEventType } from "@/types";
import core from "@/core";
import EstimateService from "@/services/work/estimate.service";
import CategoryService from "@/services/work/category.service";
import TeamService from "@/services/team/team.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    name: "아파트명",
    address1: "주소 ",
    address2: "상세주소 ",
    color: "컬러테마 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "MgmtPlaceAddEdit",
  components: {},
  data: () => ({
    title: "",
    type: "",
    place: null as any,
    placeId: 0,
    formProcessing: false,
    form: {
      name: "",
      address1: "",
      address2: "",
      latitude: null,
      longitude: null,
      color: "",
      bankAccountId: null as any,
      expositionDate: null,
      promotionDate: null,
      cleaningSitePaymentYn: "",
    },
    colorItems: ColorItems,
    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    expositionDatePicker: {
      visible: false,
    },
    promotionDatePicker: {
      visible: false,
    },
    categoryList: null as any,
    placeList: null as any,
    selectedPlace: null as any,
    teamList: [] as any,
    companyBankAccount: {
      list: [] as any,
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.type = params.type;

      const query = this.$route.query as any;
      if (this.type === "add") {
        this.title = "아파트 추가";
      } else if (this.type === "edit") {
        this.title = "아파트 수정";
        this.placeId = Number(query.id);
        if (this.placeId <= 0) {
          this.notFound();
          return;
        }
      }

      try {
        const categoryList = (await this.$store.getters["app/getCategoryList"]()) as any;
        this.categoryList = categoryList;

        const teamList = (await this.$store.getters["app/getTeamList"]()) as any;
        this.teamList = teamList;
        this.categoryList.forEach((category) => {
          category.defaultTeam = null;
          category.teamList = [];
          category.teamList.push({ id: null, name: "없음" });

          teamList.forEach((team) => {
            if (team.category.id == category.id) {
              category.teamList.push(team);
            }
          });
          if (this.type === "add") {
            if (category.name === "탄성코트") {
              category.teamList.forEach((team) => {
                if (team.name === "줄눈하이") {
                  category.defaultTeam = team.id;
                }
              });
            }
          }
        });

        if (this.type === "edit") {
          const place = (await PlaceService.getPlace(this.placeId)) as any;
          // console.log("place : ", place);
          this.form.name = place.name;
          this.form.address1 = place.address1;
          this.form.address2 = place.address2;
          this.form.latitude = place.latitude;
          this.form.longitude = place.longitude;
          this.form.color = place.color;
          if (place.bankAccountId == null) place.bankAccountId = null;
          this.form.bankAccountId = place.bankAccountId;
          this.form.expositionDate = place.expositionDate;
          this.form.promotionDate = place.promotionDate;

          this.place = place;
          if (place.options != null) {
            if (place.options.cleaningSitePaymentYn != null) {
              this.form.cleaningSitePaymentYn = place.options.cleaningSitePaymentYn;
            }
            if (place.options.categoryTeam != null) {
              const categoryTeam = place.options.categoryTeam;
              for (const key of Object.keys(categoryTeam)) {
                const teamId = categoryTeam[key];
                this.categoryList.some((category) => {
                  if (category.id == key) {
                    category.defaultTeam = teamId;
                    return true;
                  }
                });
              }
            }
          }
        }

        // 생성 후 호출
        const user = (await this.$store.getters["auth/user"]()) as UserModel;
        const company = user.company;
        if (company.detailList != null) {
          // console.log("company : ", company);

          {
            const item = {
              id: null,
            } as any;
            item.text = "없음";
            this.companyBankAccount.list.push(item);
          }
          company.detailList.forEach((detail) => {
            if (detail.type === "DEFAULT_BANK_ACCOUNT" || detail.type === "BANK_ACCOUNT") {
              const array = detail.value.split(",");
              if (array.length === 3) {
                const item = {
                  id: detail.id,
                  bankName: array[0],
                  bankAccount: array[1],
                  accountHolder: array[2],
                } as any;
                item.text = `${item.bankName}, ${item.bankAccount}, ${item.accountHolder}`;
                this.companyBankAccount.list.push(item);
              }
            }
          });
        } else {
          // TODO : 업체 계좌번호 등록 안내
        }
      } catch (e) {
        console.log(e);
        this.notFound();
      }
    });
  },
  watch: {
    type(val) {
      if (val === "add") {
        this.title = "아파트 추가";
      } else if (val === "edit") {
        this.title = "아파트 수정";
      }
    },
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
    async selectedPlace(place) {
      console.log("visible : ", this.modal.place.visible);
      if (place != null) {
        const result = await core.alert.show({
          title: "확인",
          body: `<span class="blue--text">${this.place.name}</span> 아파트로 등록된 모든 견적서를 <span class="blue--text">${place.name}</span> 아파르로 이동하시겠습니까?`,
          showCancelButton: true,
          cancelButtonText: "취소",
          confirmButtonText: "계속",
        });
        if (result === "confirm") {
          core.loader.show("저장중...");
          try {
            const result = await EstimateService.changePlace(this.place.id, place.id);
            console.log("result : ", result);
            await this.deleteItem();
          } catch (e) {
            console.log(e);
          }
        }
        this.selectedPlace = null;
      }
    },
  },

  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        const location = event.item as any;
        this.form.address1 = location.address1;
        this.form.address2 = location.address2;
        this.form.latitude = location.latitude;
        this.form.longitude = location.longitude;
        console.log("location : ", location);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");

        try {
          const categoryTeam = {};
          this.categoryList.forEach((category) => {
            if (category.defaultTeam != null) {
              categoryTeam[category.id] = category.defaultTeam;
            }
          });

          const params = core.utils.deepCopy(this.form);
          if (Object.keys(categoryTeam).length > 0) {
            const options = {} as any;
            options.categoryTeam = categoryTeam;
            params.options = options;
          }
          if (params.options == null) params.options = {};
          // console.log("params : ", params);

          if (this.isNotBlank(params.cleaningSitePaymentYn)) {
            params.options.cleaningSitePaymentYn = params.cleaningSitePaymentYn;
          }

          if (this.type === "add") {
            const place = await PlaceService.create(params);
            // console.log("create place : ", place);
            this.goBack(UpdateEventType.UPDATE, place);
          } else if (this.type === "edit") {
            const place = await PlaceService.update(this.placeId, params);
            //console.log("update place : ", place);
            this.goBack(UpdateEventType.UPDATE, place);
          }
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
        core.loader.hide();
      }
    },
    async deleteItem() {
      if (this.selectedPlace == null && this.place.options.estimateSize > 0) {
        const result = await core.alert.show({
          title: "확인",
          body: "해당 아파트로 작성된 견적서가 존재합니다.<br>기존 등록된 견적서 정보를 다른 아파트로 이동후 삭제됩니다.",
          showCancelButton: true,
          cancelButtonText: "취소",
          confirmButtonText: "아파트 선택",
        });
        if (result === "confirm") {
          if (this.placeList == null) {
            try {
              const placeList = (await this.$store.getters["app/getPlaceList"]()) as any;
              const length = placeList.length;
              for (let i = 0; i < length; i++) {
                const place = placeList[i];
                if (place.id == this.place.id) {
                  placeList.splice(i, 1);
                  break;
                }
              }
              this.placeList = placeList;
            } catch (e) {
              console.log(e);
            }
          }
          const modal = this.modal.place;
          modal.visible = true;
        }
        return;
      }
      let result = "";
      if (this.selectedPlace == null) {
        result = (await core.alert.show({
          title: "확인",
          body: "삭제를 계속 진행하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        })) as string;
      } else {
        result = "confirm";
      }

      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const result = await PlaceService.delete(this.placeId);
          console.log("result : ", result);
          core.loader.hide();
          this.goBack(UpdateEventType.DELETE, this.place);
        } catch (e) {
          console.log(e);
          core.loader.hide();
        }
      }
    },
    showSearchAddressPage() {
      console.log("showSearchAddressPage");
      let params = null as any;
      if (this.form.address1 != null && this.form.address1.length > 0) {
        params = {
          address1: this.form.address1,
          address2: this.form.address2,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          level: 2,
        };
      }

      this.$router.push({ path: "/search-address", query: params });
    },
  },
});
