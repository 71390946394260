
import mixins from "vue-typed-mixins";
import EstimateService from "@/services/work/estimate.service";
import UpdateEvent from "@/models";
import { AppBarMenuItem, EntityType, TableOrder, UpdateEventType } from "@/types";
import EstimateSortModal from "@/modals/estimate/EstimateSortModal.vue";
import core from "@/core";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import MixinsPageForm from "@/mixins/page-form";
import ScopeService from "@/services/work/scope.service";
import { UserModel } from "@/models/user/user.model";
import SpecialPriceService from "@/services/work/special-price.service";
import UnitPriceService from "@/services/unit-price/unit-price.service";
import PlaceService from "@/services/work/place.service";
import SmsService from "@/services/sms.service";
import EventService from "@/services/event/event.service";
import { Decimal } from "decimal.js";
import ExpositionAddEditEventModal from "@/modals/exposition/AddEditEventModal.vue";
import ExpoEventService from "@/services/expo/expo-event.service";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import EstimateDiscountModal from "@/modals/estimate/EstimateDiscountModal.vue";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
    dong: "동 ",
    ho: "호 ",
    customerPhone: "휴대전화 ",
    customerName: "고객명 ",
    workScope: "작업범위 ",
    date: "작업일정 ",
    price: "금액 ",
    discount: "할인금액 ",
    cleaningSitePaymentYn: "청소현장 결제 여부 ",
  },
});

export class CategoryItem {
  id: number;
  name: string;
  totalPrice: number;
  totalUnitPrice: number;
  totalDiscount: number;
  estimateDetailList: EstimateDetail[];
  alertMessage: string | null;
  eventWinner: any;

  // 폴리우레아(유광)
  totalPrice1: number | null;
  totalUnitPrice1: number | null;
  totalDiscount1: number | null;
  // 폴리우레아(무광)
  totalPrice2: number | null;
  totalUnitPrice2: number | null;
  totalDiscount2: number | null;
  // 케라폭시
  totalPrice3: number | null;
  totalUnitPrice3: number | null;
  totalDiscount3: number | null;

  constructor(
      id: number,
      name: string,
      totalPrice: number,
      totalUnitPrice: number,
      totalDiscount: number,
      estimateDetailList: EstimateDetail[]
  ) {
    this.id = id;
    this.name = name;
    this.totalPrice = totalPrice;
    this.totalUnitPrice = totalUnitPrice;
    this.totalDiscount = totalDiscount;
    this.estimateDetailList = estimateDetailList;
    this.alertMessage = null;

    this.totalPrice1 = null;
    this.totalUnitPrice1 = null;
    this.totalDiscount1 = null;
    this.totalPrice2 = null;
    this.totalUnitPrice2 = null;
    this.totalDiscount2 = null;
    this.totalPrice3 = null;
    this.totalUnitPrice3 = null;
    this.totalDiscount3 = null;
  }
}

export class Category {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export interface CounselEstimateMore {
  category1StartAt: string | null; // 줄눈
  category2StartAt: string | null; // 탄성코트
  category3StartAt: string | null; // 청소
  category4StartAt: string | null; // 나노코팅
  category5StartAt: string | null; // 새집증후군
}

export class EstimateDetail {
  id: number | null;
  index: number;
  workScope: string;
  category: Category;
  price: number | string;
  priceModel: number | string;
  discount: number | string;
  discountModel: number | string;
  placeholder: string;
  discountholder: string;
  focus = false;

  constructor(
      id: number | null,
      index: number,
      workScope: string,
      price: number | string,
      discount: number | string,
      category: Category,
      placeholder: string | null,
      discountholder: string | null
  ) {
    this.id = id;
    this.index = index;
    this.workScope = workScope;
    this.price = price;
    if (typeof price === "string" && Number(price) === 0) {
      this.price = "";
      this.priceModel = "";
    } else {
      this.priceModel = Number(price) / 10000;
    }

    this.discount = discount;
    if (typeof discount === "string" && Number(discount) === 0) {
      this.discount = "";
      this.discountModel = "";
    } else {
      this.discountModel = Number(discount) / 10000;
    }
    this.category = category;
    if (placeholder == null) {
      this.placeholder = "";
    } else {
      this.placeholder = placeholder;
    }

    if (discountholder == null) {
      this.discountholder = "";
    } else {
      this.discountholder = discountholder;
    }
  }
}

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "EstimateAddEditComponent",
  components: { EstimateDiscountModal, ExpositionAddEditEventModal, EstimateSortModal },
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          type: "",
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    title: "",
    type: "",

    showEstimateDetailHelpPopup: false,

    dateTimeFormatPattern: "YYYY년 MM월 DD일 HH시 mm분",

    estimateId: 0,

    form: {
      placeId: "",
      dong: "",
      ho: "",
      customerPhone: "",
      customerName: "익명",
      type: "",
      memo: "",
      remark: "",
      detailList: [],
      expectedDate: null,
      cleaningSitePaymentYn: "Y",
    },
    datePicker: {
      visible: false,
    },

    view: {
      placeName: "",
    },
    placeList: [] as any,

    // 선택된 아파트
    selectedPlace: null as any,

    placeTypeList: [] as any, // 선택된 아파트 타입 목록

    scopeList: [] as any, // 작업범위 목록
    categoryList: [] as any, // 카테고리 목록
    selectedCategoryList: [] as any, // 카테고리 선택 목록

    unitPrice: null as any,

    storageRemarkItems: null as any,
    estimateDetailIndex: 0, // 견적 상세 인덱스

    // 견적
    estimate: {
      index: 0,
      id: 0,
      categoryItemList: [] as CategoryItem[],
      totalPrice: 0,
      lastSpecialPriceSmsPush: {},
    },

    specialPrice: null as any,
    specialPriceTypeList: null as any,
    viewEnable: {
      selectCategory: false,
      specialPrice: false,
      specialPriceViewButton: false,
      kerapoxy: false, // 케라폭시
      matte: false, // 무광
    },

    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      placeType: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      remark: {
        visible: false,
      },
      discount: {
        visible: false,
        params: {
          item: null as CategoryItem | null,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    winnerList: [] as any,
    ready: false,
    exposition: {
      enable: false,
      eventList: null as any,
      selectedEventList: [] as any,
      addEditModal: {
        visible: false,
        params: {
          type: "add",
          placeId: 0,
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
    startDate: null as any,
    counselEstimate: null as any,
    counselEstimateMore: null as any,
    counselInterval: null as any,
    hasCleanCategory: false,
  }),
  destroyed() {
    if (this.counselInterval) {
      try {
        this.updateCounselEstimate();
      } catch (e) {
        console.log(e);
      }
      clearInterval(this.counselInterval);
      this.counselInterval = null;
    }
  },
  mounted() {
    // viewer 뒤로가기시 닫힘 처리
    const routerBeforeTask = this.$store.state.app.routerBeforeTask;
    const routerBeforeTaskItem = new RouterBeforeTaskItem(this.$vnode.tag as string, () => {
      const viewerContainer = document.querySelector(".viewer-container.viewer-in");
      if (viewerContainer != null) {
        const button = viewerContainer.querySelector(".viewer-button") as any;
        if (button != null) {
          button.click();
        }
        return false;
      }
      return true;
    });
    routerBeforeTask.pushPage(routerBeforeTaskItem);

    this.$nextTick(async () => {
      core.loader.show();
      try {
        if (this.properties && this.properties.type === "exposition") {
          this.exposition.enable = true;
          this.type = "add";
        } else {
          const params = this.$route.params as any;
          this.type = params.type;
          const query = this.$route.query as any;
          if (this.type === "add") {
            this.title = "견적 작성";
            if (core.utils.validate.isNotBlank(query.phone)) {
              this.form.customerPhone = query.phone;
            }
          } else if (this.type === "edit") {
            this.title = "견적 수정";
            this.estimateId = Number(query.id);
            if (this.estimateId <= 0) {
              this.notFound();
            }
          }
        }
        this.placeList = await this.$store.getters["app/getPlaceList"]();
        this.categoryList = await this.$store.getters["app/getCategoryList"](true);
        this.scopeList = await ScopeService.getScopeList();
        this.scopeList.sort((a: any, b: any) => {
          if (a.workScope < b.workScope) return -1;
          else if (a.workScope > b.workScope) return 1;
          return 0;
        });
      } catch (e) {
        console.log(e);
      }

      if (this.type === "edit") {
        try {
          const estimateParams = (await EstimateService.get(this.estimateId)) as any;

          const estimate = this.estimate as any;
          estimate.lastSpecialPriceSmsPush = estimateParams.lastSpecialPriceSmsPush;
          estimate.id = estimateParams.id;

          this.form.placeId = estimateParams.place.id;
          this.view.placeName = estimateParams.place.name;
          await this.changedPlace(estimateParams.place);

          this.form.dong = estimateParams.dong;
          this.form.ho = estimateParams.ho;
          this.form.customerName = estimateParams.customerName;
          this.form.customerPhone = estimateParams.customerPhone;
          this.form.type = estimateParams.type;
          this.form.remark = estimateParams.remark;
          this.form.expectedDate = estimateParams.expectedDate;
          this.form.cleaningSitePaymentYn = estimateParams.cleaningSitePaymentYn;

          await this.checkEvent();

          const selectedCategoryList = [] as any;
          estimateParams.detailList.forEach((item: any) => {
            let hasItem = false;
            for (let i = 0; i < this.selectedCategoryList.length; i++) {
              const category: any = this.selectedCategoryList[i];
              if (category.id === item.category.id) {
                hasItem = true;
                break;
              }
            }

            if (!hasItem) {
              for (let i = 0; i < this.categoryList.length; i++) {
                const category: any = this.categoryList[i];
                if (category.id === item.category.id) {
                  this.selectedCategoryList.push(category);
                  selectedCategoryList.push(category);
                  break;
                }
              }
            }
          });

          // 카테고리 목록 변경될때 까지 대기
          await new Promise((resolve: any) => {
            const interval = setInterval(() => {
              let clear = true;
              selectedCategoryList.forEach((category: any) => {
                let hasItem = false;
                for (let i = 0; i < estimate.categoryItemList.length; i++) {
                  const categoryItem = estimate.categoryItemList[i];
                  if (categoryItem.id === category.id) {
                    hasItem = true;
                    // categoryItem.estimateDetailList = [];
                    break;
                  }
                }
                if (!hasItem) {
                  clear = false;
                }
              });
              if (clear) {
                clearInterval(interval);
                resolve();
              }
            }, 50);
          });

          estimateParams.detailList.forEach((item: any) => {
            const categoryItem: CategoryItem | null = this.getCategoryItem(item.category.id);
            if (categoryItem == null) {
              console.log("categoryItem not found");
              return;
            }
            let deleteEstimateDetailIndex = null as any;
            let placeholder = null as string | null;
            let discountholder = null as string | null;
            categoryItem.estimateDetailList.some((estimateDetail, index) => {
              if (estimateDetail.workScope == item.workScope) {
                deleteEstimateDetailIndex = index;
                placeholder = estimateDetail.placeholder;
                discountholder = estimateDetail.discountholder;
                return true;
              }
            });
            if (deleteEstimateDetailIndex != null) {
              categoryItem.estimateDetailList.splice(deleteEstimateDetailIndex, 1);
            }
            const estimateDetailUnitPrice = new EstimateDetail(
                item.id,
                this.estimateDetailIndex++,
                item.workScope,
                Number(item.price),
                Number(item.discount),
                new Category(categoryItem.id, categoryItem.name),
                placeholder,
                discountholder
            );
            if (deleteEstimateDetailIndex != null) {
              categoryItem.estimateDetailList.splice(
                  deleteEstimateDetailIndex,
                  0,
                  estimateDetailUnitPrice
              );
            } else {
              categoryItem.estimateDetailList.push(estimateDetailUnitPrice);
            }
          });

          estimate.categoryItemList.forEach((categoryItem: CategoryItem) => {
            this.changeEstimateDetailList(categoryItem);
          });

          const changeValue = this.changeValue;
          setTimeout(() => {
            changeValue(false);
          }, 200);
        } catch (e) {
          console.log(e);
        }
      }

      const storageRemarkItems = await this.$store.dispatch(
          "storage/getStorageItem",
          "estimate-remark"
      );
      if (storageRemarkItems.list == null) {
        storageRemarkItems.list = [];
        await this.$store.dispatch("storage/setStorageItem", {
          type: "estimate-remark",
          items: storageRemarkItems,
        });
      }
      this.storageRemarkItems = storageRemarkItems;

      this.ready = true;
      core.loader.hide();
    });
  },
  watch: {
    hasCleanCategory(val) {
      console.log("changed hasCleanCategory : ", val);
    },
    async "modal.discount.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.discount);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const percentage = event.item;
          // console.log("item : ", percentage);
          const categoryItem = this.modal.discount.params.item as CategoryItem;
          categoryItem.estimateDetailList.forEach((estimateDetail) => {
            // console.log("estimateDetail : ", estimateDetail);
            let discount = 0;
            if (estimateDetail.price > 0) {
              const priceDecimal = new Decimal(estimateDetail.price);
              if (percentage > 0) {
                discount = new Decimal(estimateDetail.price)
                    .mul(new Decimal(percentage).div(100))
                    .toNumber();
              }
            } else {
              const placeHolder = Number(estimateDetail.placeholder);
              if (!isNaN(placeHolder) && placeHolder > 0) {
                if (percentage > 0) {
                  discount = new Decimal(placeHolder)
                      .mul(new Decimal(percentage).div(100))
                      .toNumber();
                }
              }
            }

            if (this.isNotBlank(estimateDetail.price) || this.isNotBlank(estimateDetail.discount)) {
              // console.log("discount : ", discount, estimateDetail.workScope);
              if (discount > 0) {
                estimateDetail.discountModel = core.utils.format.numberAndDot(
                    String(new Decimal(discount).div(10000))
                );
              } else {
                estimateDetail.discountModel = "0";
              }
              // estimateDetail.discountModel =
              this.changeEstimateDetail(categoryItem, estimateDetail, false);
            } else {
              // console.log("discount : ", discount);
              estimateDetail.discountholder = String(discount);
            }
          });

          this.changeEstimateDetailList(categoryItem);
        }
      }
    },
    async "exposition.addEditModal.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.exposition.addEditModal);
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          this.exposition.eventList = await ExpoEventService.getList(Number(this.form.placeId));
        }
      }
    },
    async "exposition.selectedEventList"() {
      const selectedEventList = this.exposition.selectedEventList;
      console.log("selectedEventList : ", selectedEventList);

      this.properties.updateEvent = new UpdateEvent(UpdateEventType.UPDATE, this.$route.path, {
        type: "selectedExpoEventList",
        expoEventList: selectedEventList,
      });
    },
    async selectedPlace(val) {
      await this.changedPlace(val);
    },
    "form.placeId"(val, prev) {
      this.changeValue();
    },
    "view.placeName"(val) {
      if (!this.isNotBlank(val)) {
        this.form.placeId = "";
      }
    },
    "form.dong"(val, prev) {
      this.changeValue();
    },
    "form.ho"(val, prev) {
      this.changeValue();
    },
    "form.customerPhone"(val, prev) {
      this.form.customerPhone = core.utils.format.hyphenPhone(val);
      this.changeValue();
      this.winnerList = [];
    },
    "form.customerName"(val, prev) {
      this.changeValue();
    },
    "viewEnable.specialPriceViewButton"(visible) {
      this.appBarChangeMenuVisible("specialPrice", visible);
    },
    async "form.type"(val, prev) {
      this.changeValue();
      // this.selectedCategoryList = [];
      this.viewEnable.specialPriceViewButton = false;
      if (this.type === "add" && val != null && this.specialPrice != null) {
        if (this.specialPrice.detailList != null) {
          this.specialPriceTypeList = [] as any;
          this.specialPrice.detailList.forEach((detail) => {
            if (detail.type === val) {
              this.specialPriceTypeList.push(detail);
              this.viewEnable.specialPriceViewButton = true;
            }
          });
        }
      } else {
        this.specialPriceTypeList = null;
      }

      if (this.ready && this.unitPrice != null && this.selectedCategoryList.length > 0) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: "이미 작성중인 견적이 있습니다.<br>해당 타입으로 초기화 하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "초기화",
        });
        if (confirmResult === "confirm") {
          this.selectedCategoryList = [];
        }
      }

      if (this.ready && this.type === "add") {
        console.log("start");
        if (this.startDate == null) {
          this.startDate = new Date();
          const params = {
            placeId: this.form.placeId,
            type: this.form.type,
          };
          this.counselEstimate = await EstimateService.createCounsel(params);
          console.log("counselEstimate : ", this.counselEstimate);
          this.counselInterval = setInterval(() => {
            this.updateCounselEstimate();
          }, 60000);
        } else {
          this.updateCounselEstimate();
        }
      }
    },
    selectedCategoryList() {
      this.changeSelectedCategoryList();
    },
  },
  methods: {
    getTotalUnitPrice(categoryItem: CategoryItem, index?: number) {
      if (categoryItem.name === "줄눈") {
        if (index != null) {
          return this.moneyFormat(String(categoryItem["totalUnitPrice" + index]));
        } else if ((this.viewEnable.matte || this.viewEnable.kerapoxy) && index == null) {
          return this.moneyFormat(String(categoryItem["totalUnitPrice1"]));
        }
      }
      return this.moneyFormat(String(categoryItem.totalUnitPrice));
    },
    getTotalDiscount(categoryItem: CategoryItem, index?: number) {
      if (categoryItem.name === "줄눈") {
        if (index != null) {
          return this.moneyFormat(String(categoryItem["totalDiscount" + index]));
        } else if ((this.viewEnable.matte || this.viewEnable.kerapoxy) && index == null) {
          return this.moneyFormat(String(categoryItem["totalDiscount1"]));
        }
      }
      return this.moneyFormat(String(categoryItem.totalDiscount));
    },
    getTotalPrice(categoryItem: CategoryItem, index?: number) {
      if (categoryItem.name === "줄눈") {
        if (index != null) {
          return this.moneyFormat(String(categoryItem["totalPrice" + index]));
        } else if ((this.viewEnable.matte || this.viewEnable.kerapoxy) && index == null) {
          return this.moneyFormat(String(categoryItem["totalPrice1"]));
        }
      }
      return this.moneyFormat(String(categoryItem.totalPrice));
    },
    async sendSampleEstimate() {
      try {
        await this.validate();
        const validateErrors = this.getValidateErrors();
        if (validateErrors.customerPhone.length === 0) {
          // console.log("validateErrors : ", validateErrors);
          const estimateId = 29511;
          const params = {
            customerPhone: this.form.customerPhone,
          };
          core.loader.show("문자 전송중...");
          const smsPushId = await EstimateService.sendSampleSms(estimateId, params);
          const interval = setInterval(async () => {
            try {
              const apiResult = (await SmsService.getSmsResult(smsPushId as string)) as any;
              if (apiResult.result) {
                clearInterval(interval);
                core.loader.hide();
                await core.alert.show({
                  title: "알림",
                  body: "문자 전송 완료",
                });
              } else if (apiResult.code < 0) {
                clearInterval(interval);
                core.loader.hide();
                await core.alert.show({
                  title: "알림",
                  body: apiResult.message,
                });
              }
            } catch (e) {
              core.loader.hide();
              console.log(e);
            }
          }, 2000);
        }
      } catch (e) {
        core.loader.hide();
        console.log(e);
      }
    },
    async updateCounselEstimate(estimateId?: number | undefined) {
      const counselEstimate = this.counselEstimate;
      if (counselEstimate != null) {
        const params = {
          placeId: this.form.placeId,
          type: this.form.type,
          more: this.counselEstimateMore,
        } as any;
        if (estimateId != null) {
          params.estimateId = estimateId;
        }
        this.counselEstimate = await EstimateService.updateCounsel(counselEstimate.id, params);
        console.log("update counselEstimate : ", this.counselEstimate);
      }
    },
    validEventDiscountPrice(categoryItem: CategoryItem, winner) {
      const roulette = winner.eventRoulette;
      const eventWorkScope = "[이벤트 당첨] " + roulette.item;
      if (eventWorkScope.indexOf("10%") > -1 || eventWorkScope.indexOf("5%") > -1) {
        let cal = 0.1;
        if (eventWorkScope.indexOf("5%") > -1) {
          cal = 0.05;
        }
        let totalUnitPrice = 0;
        let totalDiscount = 0;
        let totalPrice = 0;
        let estimateDetail = null as any;
        categoryItem.estimateDetailList.forEach((_estimateDetail) => {
          if (_estimateDetail.workScope != eventWorkScope) {
            totalUnitPrice += Number(_estimateDetail.price);
            totalDiscount += Number(_estimateDetail.discount);
            totalPrice = totalUnitPrice - totalDiscount;
          } else {
            estimateDetail = _estimateDetail;
          }
        });
        if (estimateDetail != null) {
          const discount = totalPrice * cal;
          if (Number(estimateDetail.discount) === discount) {
            return true;
          } else {
            console.log("estimateDetail discount : ", estimateDetail.discount);
            console.log("discount : ", discount);
          }
        }
      }
      return false;
    },
    eventDiscountPrice(categoryItem: CategoryItem, winner) {
      const roulette = winner.eventRoulette;
      const eventWorkScope = "[이벤트 당첨] " + roulette.item;
      if (eventWorkScope.indexOf("10%") > -1 || eventWorkScope.indexOf("5%") > -1) {
        let cal = 0.1;
        if (eventWorkScope.indexOf("5%") > -1) {
          cal = 0.05;
        }
        let totalUnitPrice = 0;
        let totalDiscount = 0;
        let totalPrice = 0;
        categoryItem.estimateDetailList.forEach((estimateDetail) => {
          if (estimateDetail.workScope != eventWorkScope) {
            totalUnitPrice += Number(estimateDetail.price);
            totalDiscount += Number(estimateDetail.discount);
            totalPrice = totalUnitPrice - totalDiscount;
          }
        });
        let discount = totalPrice * cal;
        return this.moneyFormat(String(discount));
      }
      return 0;
    },
    updateEventDiscountPrice(categoryItem: CategoryItem, winner) {
      const roulette = winner.eventRoulette;
      const eventWorkScope = "[이벤트 당첨] " + roulette.item;
      if (eventWorkScope.indexOf("10%") > -1 || eventWorkScope.indexOf("5%") > -1) {
        let cal = 0.1;
        if (eventWorkScope.indexOf("5%") > -1) {
          cal = 0.05;
        }
        let totalUnitPrice = 0;
        let totalDiscount = 0;
        let totalPrice = 0;
        let estimateDetail = null as any;
        categoryItem.estimateDetailList.forEach((_estimateDetail) => {
          if (_estimateDetail.workScope != eventWorkScope) {
            totalUnitPrice += Number(_estimateDetail.price);
            totalDiscount += Number(_estimateDetail.discount);
            totalPrice = totalUnitPrice - totalDiscount;
          } else {
            estimateDetail = _estimateDetail;
          }
        });
        if (estimateDetail != null) {
          estimateDetail.discountModel = core.utils.format.numberAndDot(
              String(new Decimal(totalPrice * cal).div(10000))
          );
          this.changeEstimateDetail(categoryItem, estimateDetail);
        }
      }
    },
    getFilename(uri: string) {
      if (uri != null) {
        const pos = uri.lastIndexOf("/");
        if (pos > -1) {
          return uri.substring(pos + 1, uri.length);
        }
      }
      return uri;
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "specialPrice") {
        this.viewEnable.specialPrice = true;
        this.viewEnable.specialPriceViewButton = false;
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    showAddExpositionEventModal() {
      const modal = this.exposition.addEditModal;
      modal.params.type = "add";
      modal.params.placeId = Number(this.form.placeId);
      modal.visible = true;
    },
    showEditExpositionEventModal(item: any) {
      const modal = this.exposition.addEditModal;
      modal.params.type = "edit";
      modal.params.placeId = Number(this.form.placeId);
      modal.params.item = item;
      modal.visible = true;
    },
    getCategoryName(categoryId) {
      let category = null as any;
      this.categoryList.some((_category) => {
        if (categoryId === _category.id) {
          category = _category;
          return true;
        }
      });
      if (category != null) return category.name;
      return "";
    },
    // showImageView(imageUri) {
    //   window.open(imageUri, "_blank");
    // },
    showImageView(uri: string) {
      let selected = null as any;
      this.$viewer.images.some((el) => {
        if (el.src.indexOf(uri) > -1) {
          selected = el;
        }
      });
      if (selected != null) {
        selected.click();
      } else {
        this.$viewer.show();
      }
    },
    showRemarkHistory() {
      console.log("show remark history");
      const modal = this.modal.remark;
      modal.visible = true;
    },
    getCategoryItem(categoryId: number): CategoryItem | null {
      const categoryItemList = this.estimate.categoryItemList;
      for (let i = 0; i < categoryItemList.length; i++) {
        const categoryItem = categoryItemList[i];
        if (categoryItem.id == categoryId) return categoryItem;
      }
      return null;
    },
    async changedPlace(place) {
      // console.log("changed place :", place);
      const user = (await this.$store.getters["auth/user"]()) as UserModel;

      if (place != null && typeof place === "object") {
        if (place.typeList != null) {
          const typeList = [] as any;
          place.typeList.forEach((type) => {
            typeList.push({ id: type, name: type });
          });
          this.placeTypeList = typeList;
        } else {
          this.placeTypeList = [];
        }
        console.log("placeTypeList : ", this.placeTypeList);
        this.form.type = "";

        try {
          const specialPriceTable = (await SpecialPriceService.getTable({
            draw: 0,
            start: 0,
            length: 100,
            orderColumnName: "id",
            order: TableOrder.DESC,
            searchColumns: { placeId: place.id },
          })) as any;
          if (specialPriceTable.recordsTotal === 1) {
            this.specialPrice = await SpecialPriceService.get(specialPriceTable.data[0].id);
          } else {
            this.specialPrice = null;
          }

          let permission = false;
          if (this.isCompanyManagerRoleHigher) {
            permission = true;
          } else {
            const options = user.company.options as any;
            if (options != null && options.accessPlaceList != null) {
              options.accessPlaceList.some((accessPlace) => {
                if (accessPlace.id == place.id) {
                  permission = true;
                  return true;
                }
              });
            }
          }

          if (permission) {
            const unitPriceTable = (await UnitPriceService.getTable({
              draw: 0,
              start: 0,
              length: 100,
              orderColumnName: "id",
              order: TableOrder.DESC,
              searchColumns: { placeId: place.id },
            })) as any;
            if (unitPriceTable.recordsTotal === 1) {
              this.unitPrice = await UnitPriceService.get(unitPriceTable.data[0].id);
            } else {
              this.unitPrice = null;
            }
          }

          if (this.exposition.enable) {
            this.exposition.eventList = await ExpoEventService.getList(place.id);
          }
        } catch (e) {
          console.log(e);
        }
        if (place.options != null && place.options.cleaningSitePaymentYn) {
          this.form.cleaningSitePaymentYn = place.options.cleaningSitePaymentYn;
        } else {
          this.form.cleaningSitePaymentYn = "N";
        }
      } else {
        this.specialPrice = null;
      }
    },
    isNotBlank(val) {
      return core.utils.validate.isNotBlank(val);
    },
    async createPlace(keyword, itemList) {
      try {
        if (itemList != null && itemList.length > 0) {
          let placeName = "";
          itemList.forEach((item) => {
            placeName += item.name + ",";
          });
          placeName = placeName.substr(0, placeName.length - 1);
          const result = await core.alert.show({
            title: "확인",
            body: `비슷한 이름의 [<span class="red--text">${placeName}</span>] 아파트가 존재합니다.<br>새로 생성하시겠습니까?`,
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "생성",
          });
          if (result !== "confirm") return;
        }
        const item: any = await PlaceService.create({ name: keyword });
        await this.$store.dispatch("app/waitForPlaceUpdate", item.id);
        return item;
      } catch (e: any) {
        console.log(e);
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      }
    },
    async createType(keyword) {
      // console.log("keyword : ", keyword);
      const item = { id: keyword, name: keyword };
      this.placeTypeList.push(item);
      return item;
    },
    async submitSpecialPrice() {
      const confirmResult = await core.alert.show({
        title: "확인",
        body: `특가표를 전송하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: "저장",
      });
      if (confirmResult !== "confirm") {
        return;
      }

      try {
        core.loader.show("특가표 중복 전송 검사중...");

        {
          const params = {
            entityType: EntityType.ESTIMATE,
            placeId: this.form.placeId,
            type: this.form.type,
            customerPhone: this.form.customerPhone,
          } as any;
          const apiResult = (await EstimateService.existsEstimate(params)) as any;
          if (apiResult.exists) {
            const result = await core.alert.show({
              title: "확인",
              body: "이미 작성된 견적이 존재합니다.<br>견적서를 불러오시겠습니까?",
              showCancelButton: true,
              cancelButtonText: "아니오",
              confirmButtonText: "예",
            });
            if (result === "confirm") {
              if (this.exposition.enable) {
                this.$router.push({
                  path: "/estimate/edit",
                  query: { id: apiResult.estimateId },
                });
              } else {
                this.goBack(UpdateEventType.PAGE, {
                  path: "/estimate/edit",
                  query: { id: apiResult.estimateId },
                });
              }
              return;
            }
          }
        }
        core.loader.show("특가표 전송중...");
        const params = core.utils.deepCopy(this.form);
        params.contractStatus = "SPECIAL_PRICE";
        console.log("params : ", params);
        const estimate = (await EstimateService.createTemporary(params)) as any;
        await this.sendSms(estimate.id, true);
        if (this.exposition.enable) {
          this.$router.push({
            path: "/estimate/edit",
            query: { id: estimate.id },
          });
        } else {
          this.goBack(UpdateEventType.PAGE, {
            path: "/estimate/edit",
            query: { id: estimate.id },
            estimate: estimate,
          });
        }
      } catch (e: any) {
        console.log(e);
        core.alert.show({
          title: "알림",
          body: e.message,
        });
      } finally {
        core.loader.hide();
      }
    },
    async submit() {
      if (await this.validate()) {
        if (this.exposition.enable) {
          let showAlert = false;
          for (const categoryItem of this.estimate.categoryItemList) {
            categoryItem.estimateDetailList.some((estimateDetail: EstimateDetail) => {
              if (estimateDetail.category.name === "줄눈") {
                showAlert = true;
                return true;
              }
            });
            if (showAlert) {
              break;
            }
          }
          if (showAlert) {
            const title = "* 줄눈 유의사항";
            const body =
                "<div style='font-size: 1.025rem'>" +
                "<div class='pt-1 pb-1'>1. 무광 바닥 줄눈 진행 시 테두리 와 실리콘 부분은 유광으로 진행(바닥 타일과 타일 사이 + 무광진행)되며, 케라폭시 또한 실리콘부분은 유광으로 시공됩니다.</div>" +
                "<div class='pt-1 pb-1'>2. 서비스는 모두 유광 줄눈으로 진행됩니다.</div>" +
                "<div class='pt-1 pb-1'>3. 일반(유광,무광) 줄눈은 8시간 양생기간이 필요하며, 케라폭시는 2일~3일 양생기간이 필요합니다.</div>" +
                "<div class='pt-1 pb-1'>4. 줄눈 시공시 줄눈만 시공 가능하오니, 스케줄 유의하여 주십시오.(현관시공으로 인해 다른시공 불가능)</div></div>";
            const confirmResult = await core.alert.show({
              title: title,
              body: body,
              confirmButtonText: "확인",
            });
          }
        }
        const confirmResult = await core.alert.show({
          title: "확인",
          body: `${this.type === "add" ? "작성" : "수정"}된 견적서를 저장하시겠습니까?`,
          showCancelButton: true,
          cancelButtonText: "취소",
          confirmButtonText: "저장",
        });
        if (confirmResult !== "confirm") {
          return;
        }

        core.loader.show();

        // 견적 저장
        const type = this.type;
        const params = core.utils.deepCopy(this.form);
        if (params.dong == null) params.dong = "";
        if (params.ho == null) params.ho = "";

        if (type === "add") {
          const temp = this.isBlank(params.dong) && this.isBlank(params.ho);
          if (temp) {
            params.contractStatus = "TEMPORARY";
          }

          if (!temp) {
            const existsParams = {
              entityType: EntityType.ESTIMATE,
              placeId: params.placeId,
              dong: params.dong,
              ho: params.ho,
            };
            const apiResult = (await EstimateService.existsEstimate(existsParams)) as any;
            if (apiResult.exists) {
              const result = await core.alert.show({
                title: "확인",
                body: "이미 작성된 견적이 존재합니다.<br>계속 진행하시겠습니까?",
                showCancelButton: true,
                cancelButtonText: "아니오",
                confirmButtonText: "예",
              });
              if (result !== "confirm") {
                return;
              }
            }
          } else if (this.isNotBlank(params.type) && this.isNotBlank(params.customerPhone)) {
            const existsParams = {
              entityType: EntityType.ESTIMATE,
              placeId: this.form.placeId,
              type: this.form.type,
              customerPhone: this.form.customerPhone,
            } as any;
            const apiResult = (await EstimateService.existsEstimate(existsParams)) as any;
            if (apiResult.exists) {
              const result = await core.alert.show({
                title: "확인",
                body: "이미 작성된 견적이 존재합니다.<br>계속 진행하시겠습니까?",
                showCancelButton: true,
                cancelButtonText: "아니오",
                confirmButtonText: "예",
              });
              if (result !== "confirm") {
                return;
              }
            }
          }
        }

        const estimateDetailList = [] as any;
        for (const categoryItem of this.estimate.categoryItemList) {
          let no = 1;
          if (categoryItem.eventWinner != null) {
            const valid = this.validEventDiscountPrice(categoryItem, categoryItem.eventWinner);
            if (!valid) {
              console.log("알림 추가");
              core.alert.show({
                title: "알림",
                body: "이벤트 당첨 할인 금액을 적용해주세요!",
              });
              return;
            }
          }
          categoryItem.estimateDetailList.forEach((estimateDetail: EstimateDetail) => {
            estimateDetailList.push(estimateDetail);
            const detailParam = {
              categoryId: estimateDetail.category.id,
              workScope: estimateDetail.workScope,
              price: estimateDetail.price,
              discount: estimateDetail.discount,
              no: no,
            } as any;
            no++;

            if (estimateDetail.id != null) {
              detailParam.id = estimateDetail.id;
            }
            params.detailList.push(detailParam);
          });
        }
        // this.estimate.categoryItemList.forEach((categoryItem: CategoryItem) => {
        //   let no = 1;
        //   if (categoryItem.eventWinner != null) {
        //     const valid = this.validEventDiscountPrice(categoryItem, categoryItem.eventWinner);
        //     if (!valid) {
        //       console.log("알림 추가");
        //       core.alert.show({
        //         title: "알림",
        //         body: "이벤트 당첨 할인 금액을 적용해주세요!",
        //       });
        //       return;
        //     }
        //   }
        //   categoryItem.estimateDetailList.forEach((estimateDetail: EstimateDetail) => {
        //     estimateDetailList.push(estimateDetail);
        //     const detailParam = {
        //       categoryId: estimateDetail.category.id,
        //       workScope: estimateDetail.workScope,
        //       price: estimateDetail.price,
        //       discount: estimateDetail.discount,
        //       no: no,
        //     } as any;
        //     no++;
        //
        //     if (estimateDetail.id != null) {
        //       detailParam.id = estimateDetail.id;
        //     }
        //     params.detailList.push(detailParam);
        //   });
        // });

        const detailList = [] as any;
        params.detailList.forEach((detail) => {
          if (
              core.utils.validate.isNotBlank(detail.workScope) &&
              core.utils.validate.isNotBlank(detail.price)
          ) {
            detailList.push(detail);
          }
        });
        params.detailList = detailList;

        // if (params.detailList.length === 0) {
        //   core.loader.hide();
        //   await core.alert.show({
        //     title: "확인",
        //     body: "추가된 작업범위가 없습니다!",
        //     confirmButtonText: "예",
        //   });
        //   return;
        // }

        try {
          console.log("params : ", params);
          let estimate = null as any;
          if (type === "add") {
            estimate = (await EstimateService.create(params)) as any;
            console.log("create estimate : ", estimate);
            if (this.counselEstimate != null) {
              this.updateCounselEstimate(estimate.id);
            }
          } else if (type === "edit") {
            estimate = (await EstimateService.update(this.estimateId, params)) as any;
            console.log("update estimate : ", estimate);
          } else {
            console.log("unknown type : ", this.type);
            return;
          }
          core.loader.hide();

          if (this.isNotBlank(estimate.remark)) {
            const storageRemarkItems = this.storageRemarkItems;
            const remark = estimate.remark.trim();
            let exists = false;
            storageRemarkItems.list.some((item: any) => {
              if (item.text === remark) {
                exists = true;
                item.time = new Date().getTime();
                return true;
              }
            });

            if (exists) {
              // 정렬
              storageRemarkItems.list.sort((a: any, b: any) => {
                if (a.time > b.time) return -1;
                else if (a.time < b.time) return 1;
                return 0;
              });
            } else {
              storageRemarkItems.list.unshift({
                time: new Date().getTime(),
                text: remark,
              });
              const maxLength = 30;
              if (storageRemarkItems.list.length > maxLength) {
                const length = storageRemarkItems.list.length;
                for (let i = maxLength; i < length; i++) {
                  storageRemarkItems.list.splice(i, 1);
                }
              }
            }

            await this.$store.dispatch("storage/setStorageItem", {
              type: "estimate-remark",
              items: storageRemarkItems,
            });
          }

          //await this.sendSms(estimate.id);
          if (this.exposition.enable) {
            this.$router.push("/estimate/" + estimate.id);
          } else {
            this.goBack(UpdateEventType.UPDATE, estimate);
          }
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    async sendSms(estimateId: number, isSpecialPrice?: boolean) {
      if (isSpecialPrice == null) isSpecialPrice = false;

      let result = "";

      if (!isSpecialPrice) {
        result = (await core.alert.show({
          title: "확인",
          body: "견적서 정보를 고객에게 문자로 전송하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        })) as string;
      } else {
        result = "confirm";
      }

      let promiseResolve = null as any;
      let promiseReject = null as any;
      if (result === "confirm") {
        try {
          core.loader.show("문자 전송중...");
          const smsPushId = await EstimateService.sendSms(estimateId, { type: "ESTIMATE" });
          const interval = setInterval(async () => {
            try {
              const apiResult = (await SmsService.getSmsResult(smsPushId as string)) as any;
              if (apiResult.result) {
                clearInterval(interval);
                core.loader.hide();
                await core.alert.show({
                  title: "알림",
                  body: "문자 전송 완료",
                });
                promiseResolve();
              } else if (apiResult.code < 0) {
                clearInterval(interval);
                core.loader.hide();
                await core.alert.show({
                  title: "알림",
                  body: apiResult.message,
                });
                promiseReject();
              }
            } catch (e) {
              console.log(e);
              promiseReject();
            }
          }, 2000);
        } catch (e) {
          console.log(e);
        }
        return new Promise((resolve: any, reject) => {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      }
      return new Promise((resolve: any, reject) => {
        resolve();
      });
    },
    async checkTempEstimate() {
      const type = this.type;
      if (type === "edit") {
        await this.checkEstimate();
      } else {
        // 임시 견적 확인
        const searchColumns = {
          placeId: this.form.placeId,
          customerPhone: this.form.customerPhone.replaceAll("-", ""),
          contractStatusList: "SPECIAL_PRICE,TEMPORARY",
        };
        const tableResponse = (await EstimateService.getTable({
          draw: 0,
          start: 0,
          length: 10,
          orderColumnName: "id",
          order: TableOrder.DESC,
          searchColumns: searchColumns,
        })) as any;
        if (tableResponse.data.length > 0) {
          const estimate = tableResponse.data[0];
          const result = await core.alert.show({
            title: "확인",
            body: "이미 작성된 견적이 존재합니다.<br>견적서를 불러오시겠습니까?",
            showCancelButton: true,
            cancelButtonText: "아니오",
            confirmButtonText: "예",
          });
          if (result === "confirm") {
            if (this.exposition.enable) {
              this.$router.push({
                path: "/estimate/edit",
                query: { id: estimate.id },
              });
            } else {
              this.goBack(UpdateEventType.PAGE, {
                path: "/estimate/edit",
                query: { id: estimate.id },
              });
            }
          }
        } else {
          await this.checkEstimate();
        }
      }
    },
    async checkEstimate() {
      const params = {
        entityType: EntityType.ESTIMATE,
        placeId: this.form.placeId,
        dong: this.form.dong,
        ho: this.form.ho,
      } as any;
      let isValid = true;
      for (const key of Object.keys(params)) {
        const value = params[key] as string;
        if (value == null || value.length === 0) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        const apiResult = (await EstimateService.existsEstimate(params)) as any;
        if (apiResult.exists) {
          const type = this.type;
          if (type === "edit") {
            const estimateParams = this.estimate;
            let changed = true;
            for (const key of Object.keys(params)) {
              const paramValue: string = params[key] as string;
              const estimateParamValue: string = estimateParams[key] as string;
              if (paramValue !== estimateParamValue) {
                changed = false;
              }
              if (!changed) {
                return;
              }
            }
          }
          const result = await core.alert.show({
            title: "확인",
            body: "이미 작성된 견적이 존재합니다.<br>견적서를 불러오시겠습니까?",
            showCancelButton: true,
            cancelButtonText: "아니오",
            confirmButtonText: "예",
          });
          if (result === "confirm") {
            if (this.exposition.enable) {
              this.$router.push({
                path: "/estimate/edit",
                query: { id: apiResult.estimateId },
              });
            } else {
              this.goBack(UpdateEventType.PAGE, {
                path: "/estimate/edit",
                query: { id: apiResult.estimateId },
              });
            }
          }
        }
      }

      await this.checkEvent();
    },
    async checkEvent() {
      // 이벤트 당첨 정보 조회
      try {
        if (
            this.isNotBlank(this.form.placeId) &&
            this.isNotBlank(this.form.customerPhone) &&
            this.isNotBlank(this.form.dong) &&
            this.isNotBlank(this.form.ho)
        ) {
          const params = {
            phone: this.form.customerPhone,
            placeId: this.form.placeId,
            dong: this.form.dong,
            ho: this.form.ho,
          };
          const winnerList = (await EventService.getRouletteWinnerList(params)) as any;
          //console.log("winnerList : ", winnerList);
          this.winnerList = winnerList;
        }
      } catch (e) {
        console.log(e);
      }
    },
    addEstimateDetail(categoryItem: CategoryItem) {
      // console.log("addEstimateDetail : ", categoryItem);
      const estimateDetailUnitPrice = new EstimateDetail(
          null,
          this.estimateDetailIndex++,
          "",
          "",
          "",
          new Category(categoryItem.id, categoryItem.name),
          null,
          null
      );
      categoryItem.estimateDetailList.push(estimateDetailUnitPrice);

      // this.changeEstimateDetailList(categoryItem);
    },

    async removeEstimateDetail(categoryItem: CategoryItem, estimateDetail: EstimateDetail) {
      const detailList = categoryItem.estimateDetailList;
      for (let i = 0; i < detailList.length; i++) {
        const item = detailList[i];
        if (item === estimateDetail) {
          if (this.isNotBlank(item.price) || this.isNotBlank(item.discount)) {
            item.priceModel = "";
            item.discountModel = "";
            this.changeEstimateDetail(categoryItem, estimateDetail);
          } else {
            detailList.splice(i, 1);
            this.changeEstimateDetailList(categoryItem);
          }
          break;
        }
      }
    },
    changeEstimateDetail(
        categoryItem: CategoryItem,
        estimateDetail: EstimateDetail,
        changeList?: boolean
    ) {
      //console.log("change estimate detail : ", estimateDetail);
      if (changeList == null) changeList = true;

      estimateDetail.discountModel = core.utils.format.numberAndDot(
          String(estimateDetail.discountModel)
      );
      if (core.utils.validate.isNotBlank(estimateDetail.discountModel)) {
        const discount = new Decimal(estimateDetail.discountModel);
        estimateDetail.discount = discount.mul(10000).toNumber();
      } else {
        estimateDetail.discount = "";
      }

      estimateDetail.priceModel = core.utils.format.numberAndDot(String(estimateDetail.priceModel));
      if (core.utils.validate.isNotBlank(estimateDetail.priceModel)) {
        const price = new Decimal(estimateDetail.priceModel);
        estimateDetail.price = price.mul(10000).toNumber();
        if (core.utils.validate.isBlank(estimateDetail.discountModel)) {
          estimateDetail.discountModel = "0";
          estimateDetail.discount = 0;
        }
      } else {
        estimateDetail.price = "";
      }
      //console.log("estimateDetail : ", estimateDetail);

      if (changeList) {
        this.changeEstimateDetailList(categoryItem);
      }
    },
    changeEstimateDetailPrice(
        categoryItem: CategoryItem,
        estimateDetail: EstimateDetail,
        event: any
    ) {
      if (
          core.utils.validate.isBlank(estimateDetail.price) &&
          core.utils.validate.isNotBlank(estimateDetail.placeholder)
      ) {
        console.log("change!!");
        if (event.target != null) {
          const changeEstimateDetail = this.changeEstimateDetail;

          setTimeout(() => {
            event.target.blur();

            estimateDetail.priceModel = Number(estimateDetail.placeholder);
            estimateDetail.discountModel = Number(estimateDetail.discountholder);
            changeEstimateDetail(categoryItem, estimateDetail);
          }, 1);
        }
      }
    },
    changeEstimateDetailList(categoryItem: CategoryItem) {
      const estimateDetailList = categoryItem.estimateDetailList;
      //console.log("changed estimate detail list : ", estimateDetailList);

      if (categoryItem.name === "줄눈") {
        this.viewEnable.kerapoxy = false;
        this.viewEnable.matte = false;
      }
      // 품목 별 합계 계산
      categoryItem.totalUnitPrice = 0;
      categoryItem.totalDiscount = 0;
      categoryItem.totalPrice = 0;

      if (categoryItem.name === "줄눈") {
        for (let i = 1; i <= 3; i++) {
          categoryItem["totalUnitPrice" + i] = 0;
          categoryItem["totalDiscount" + i] = 0;
          categoryItem["totalPrice" + i] = 0;
        }
      }
      estimateDetailList.forEach((item: EstimateDetail) => {
        const price = Number(item.price);
        const discount = Number(item.discount);
        categoryItem.totalUnitPrice += price;
        categoryItem.totalDiscount += discount;
        categoryItem.totalPrice = categoryItem.totalUnitPrice - categoryItem.totalDiscount;

        if (categoryItem.name === "줄눈") {
          // console.log("workScope : ", item.workScope);
          if (item.workScope.indexOf("케라폭시") > -1) {
            this.viewEnable.kerapoxy = true;
          }
          if (item.workScope.indexOf("무광") > -1) {
            this.viewEnable.matte = true;
            console.log("무광!!");
          }

          let index = 1;

          if (item.workScope.indexOf("케라폭시") > -1) {
            index = 3;
          } else if (item.workScope.indexOf("무광") > -1) {
            index = 2;
          }

          categoryItem["totalUnitPrice" + index] += price;
          categoryItem["totalDiscount" + index] += discount;
          categoryItem["totalPrice" + index] =
              categoryItem["totalUnitPrice" + index] - categoryItem["totalDiscount" + index];
        }
      });

      // 총 합계 계산
      this.estimate.totalPrice = 0;
      this.estimate.categoryItemList.forEach((categoryItem: CategoryItem) => {
        this.estimate.totalPrice += categoryItem.totalPrice;
      });
      this.changeBackButton(true);
    },
    changeSelectedCategoryList() {
      // 품목 선택 변경됬을 경우 호출됨

      // const detailListCategory = this.estimate.detailListCategory;
      const categoryItemList = this.estimate.categoryItemList;
      const selectedCategoryList = this.selectedCategoryList;

      if (this.exposition.enable) {
        this.properties.updateEvent = new UpdateEvent(UpdateEventType.UPDATE, this.$route.path, {
          type: "changedCategory",
          categoryList: selectedCategoryList,
        });
      }

      let hasCleanCategory = false;
      let updateCounselEstimate = false;
      selectedCategoryList.forEach((category: any) => {
        // console.log("selected category : ", category);
        if (category.name === "청소") {
          hasCleanCategory = true;
        }
        if (this.counselEstimate != null) {
          if (
              category.name === "줄눈" ||
              category.name === "탄성코트" ||
              category.name === "청소" ||
              category.name === "나노코팅" ||
              category.name === "새집증후군"
          ) {
            if (this.counselEstimateMore == null) {
              this.counselEstimateMore = {
                category1StartAt: null,
                category2StartAt: null,
                category3StartAt: null,
                category4StartAt: null,
                category5StartAt: null,
              } as CounselEstimateMore;
            }
            if (category.name === "줄눈" && this.counselEstimateMore.category1StartAt == null) {
              this.counselEstimateMore.category1StartAt = core.date
                  .instance()
                  .format("YYYY-MM-DDTHH:mm:ss");
            } else if (
                category.name === "탄성코트" &&
                this.counselEstimateMore.category2StartAt == null
            ) {
              this.counselEstimateMore.category2StartAt = core.date
                  .instance()
                  .format("YYYY-MM-DDTHH:mm:ss");
            } else if (
                category.name === "청소" &&
                this.counselEstimateMore.category3StartAt == null
            ) {
              this.counselEstimateMore.category3StartAt = core.date
                  .instance()
                  .format("YYYY-MM-DDTHH:mm:ss");
            } else if (
                category.name === "나노코팅" &&
                this.counselEstimateMore.category4StartAt == null
            ) {
              this.counselEstimateMore.category4StartAt = core.date
                  .instance()
                  .format("YYYY-MM-DDTHH:mm:ss");
            } else if (
                category.name === "새집증후군" &&
                this.counselEstimateMore.category5StartAt == null
            ) {
              this.counselEstimateMore.category5StartAt = core.date
                  .instance()
                  .format("YYYY-MM-DDTHH:mm:ss");
            }
            updateCounselEstimate = true;
          }
        }
        let hasItem = false;
        for (let i = 0; i < categoryItemList.length; i++) {
          const categoryItem = categoryItemList[i];
          if (categoryItem.id === category.id) {
            hasItem = true;
            break;
          }
        }
        if (!hasItem) {
          const categoryItem = new CategoryItem(category.id, category.name, 0, 0, 0, []);
          categoryItemList.push(categoryItem);
          //console.log("create category unitPriceList : ", this.unitPriceList);

          let addScope = true;

          if (this.unitPrice != null) {
            this.unitPrice.categoryList.some((unitPriceCategory) => {
              if (unitPriceCategory.categoryId === category.id) {
                const workScopeList = unitPriceCategory.workScopeList;
                workScopeList.forEach((workScope) => {
                  workScope.priceList.some((price) => {
                    if (price.type == this.form.type) {
                      addScope = false;
                      this.showEstimateDetailHelpPopup = true;

                      const placeholder = String(Number(price.price) / 10000);
                      const discountholder = String(Number(price.discount) / 10000);

                      const estimateDetailUnitPrice = new EstimateDetail(
                          null,
                          this.estimateDetailIndex++,
                          workScope.name,
                          "0",
                          "0",
                          new Category(categoryItem.id, categoryItem.name),
                          placeholder,
                          discountholder
                      );
                      categoryItem.estimateDetailList.push(estimateDetailUnitPrice);
                      return true;
                    }
                  });
                });
              }
            });
          }

          if (addScope) {
            this.scopeList.forEach((scope) => {
              if (categoryItem.id != scope.category.id) return;
              const estimateDetailUnitPrice = new EstimateDetail(
                  null,
                  this.estimateDetailIndex++,
                  scope.workScope,
                  "0",
                  "0",
                  new Category(categoryItem.id, categoryItem.name),
                  null,
                  null
              );
              categoryItem.estimateDetailList.push(estimateDetailUnitPrice);
            });
          }
        }
      });

      this.hasCleanCategory = hasCleanCategory;

      if (updateCounselEstimate) {
        this.updateCounselEstimate();
      }
      // 삭제
      let index = 0;
      categoryItemList.forEach((categoryItem: CategoryItem) => {
        let hasItem = false;
        for (let i = 0; i < selectedCategoryList.length; i++) {
          const category = selectedCategoryList[i];
          if (category.id === categoryItem.id) {
            hasItem = true;
            break;
          }
        }
        if (!hasItem) {
          categoryItemList.splice(index, 1);
        }
        index++;
      });

      //console.log("categoryItemList : ", categoryItemList);
      categoryItemList.forEach((categoryItem: CategoryItem) => {
        this.changeEstimateDetailList(categoryItem);

        console.log("이벤트 당첨자일 경우 : ", categoryItem);
        if (this.winnerList) {
          this.winnerList.some((winner) => {
            if (categoryItem.id === winner.categoryId) {
              const roulette = winner.eventRoulette;
              const eventWorkScope = "[이벤트 당첨] " + roulette.item;
              if (eventWorkScope.indexOf("10%") > -1 || eventWorkScope.indexOf("5%") > -1) {
                categoryItem.eventWinner = winner;
                let exists = false;
                categoryItem.estimateDetailList.some((estimateDetail) => {
                  if (estimateDetail.workScope == eventWorkScope) {
                    exists = true;
                    return true;
                  }
                });
                if (!exists) {
                  const estimateDetailUnitPrice = new EstimateDetail(
                      null,
                      this.estimateDetailIndex++,
                      eventWorkScope,
                      0,
                      0,
                      new Category(categoryItem.id, categoryItem.name),
                      null,
                      null
                  );

                  categoryItem.estimateDetailList.push(estimateDetailUnitPrice);
                }
              }
              return true;
            }
          });
        }
      });
    },
    changeValue(changed?: boolean) {
      if (changed == null) changed = true;
      this.changeBackButton(changed);

      const form = this.form;
      if (
          Number(form.placeId) > 0 &&
          form.customerPhone.trim().length > 0 &&
          form.customerName.trim().length > 0
      ) {
        this.viewEnable.selectCategory = true;
      } else {
        this.viewEnable.selectCategory = false;
      }
    },
    showDiscountModal(categoryItem: CategoryItem) {
      const modal = this.modal.discount;
      modal.params.item = categoryItem;
      modal.visible = true;
    },
  },
});
